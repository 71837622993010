$(document).ready(function(){
    $("#eredmeny").hide();

    $("#szamol").on('click', function(){
        $("#eredmeny").show();

        $("#adatok").submit(function(e){
            e.preventDefault();
        });

        $.ajax({
            url: '/api/kalkulator/calculate', // url where to submit the request
            type : "POST", // type of action POST || GET
            dataType : 'json', // data type
            data : $("#adatok").serialize(), // post data || get data
            success : function(result) {
                console.log(result[0])
                $('#eredmeny #alapdij #ar').text( result[0].alap + ' Ft + ÁFA / év' )

                $('#eredmeny #webtarhely_meret #mennyiseg').text( result[0].webtarhely_meret.mennyiseg + ' MB' )
                $('#eredmeny #webtarhely_meret #atvaltas').text( '(' + (result[0].webtarhely_meret.mennyiseg / 1024).toFixed(2) + ' GB)' )
                $('#eredmeny #webtarhely_meret #ar').text( result[0].webtarhely_meret.ar + ' Ft + ÁFA / év' )
                $('#eredmeny #webtarhely_meret #kedvezmeny').text( result[0].webtarhely_meret.kedvezmeny + ' Ft + ÁFA / év' )

                $('#eredmeny #adatbazis_tarhely_meret #mennyiseg').text( result[0].adatbazis_tarhely_meret.mennyiseg + ' MB' )
                $('#eredmeny #adatbazis_tarhely_meret #atvaltas').text( '(' + (result[0].adatbazis_tarhely_meret.mennyiseg / 1024).toFixed(2) + ' GB)' )
                $('#eredmeny #adatbazis_tarhely_meret #ar').text( result[0].adatbazis_tarhely_meret.ar + ' Ft + ÁFA / év' )
                $('#eredmeny #adatbazis_tarhely_meret #kedvezmeny').text( result[0].adatbazis_tarhely_meret.kedvezmeny + ' Ft + ÁFA / év' )

                $('#eredmeny #email_tarhely_meret #mennyiseg').text( result[0].email_tarhely_meret.mennyiseg + ' MB' )
                $('#eredmeny #email_tarhely_meret #atvaltas').text( '(' + (result[0].email_tarhely_meret.mennyiseg / 1024).toFixed(2) + ' GB)' )
                $('#eredmeny #email_tarhely_meret #ar').text( result[0].email_tarhely_meret.ar + ' Ft + ÁFA / év' )
                $('#eredmeny #email_tarhely_meret #kedvezmeny').text( result[0].email_tarhely_meret.kedvezmeny + ' Ft + ÁFA / év' )

                $('#eredmeny #email_postafiok_db #mennyiseg').text( result[0].email_postafiok_db.mennyiseg + ' db' )
                $('#eredmeny #email_postafiok_db #ar').text( result[0].email_postafiok_db.ar + ' Ft + ÁFA / év' )
                $('#eredmeny #email_postafiok_db #kedvezmeny').text( result[0].email_postafiok_db.kedvezmeny + ' Ft + ÁFA / év' )

                $('#eredmeny #email_kuldes_db #mennyiseg').text( result[0].email_kuldes_db.mennyiseg + ' db' )
                $('#eredmeny #email_kuldes_db #ar').text( result[0].email_kuldes_db.ar + ' Ft + ÁFA / év' )
                $('#eredmeny #email_kuldes_db #kedvezmeny').text( result[0].email_kuldes_db.kedvezmeny + ' Ft + ÁFA / év' )

                $('#eredmeny #szum_kerek_1ev #ar #kalk_min').text( result[0].szum_kerek_1ev + ' Ft + ÁFA / év' )

                if( result[0].szum_kerek_1ev < result[0].eves_min ){
                    $('#eredmeny #szum_kerek_1ev #ar #eves_min').text( result[0].eves_min + ' Ft + ÁFA / év' )
                    $('#eredmeny #szum_kerek_1ev #ar #eves_min').addClass('eredmeny_piros')
                }
                else{
                    $('#eredmeny #szum_kerek_1ev #ar #eves_min').hide()
                    $('#eredmeny #szum_kerek_1ev #ar #kalk_min').addClass('eredmeny_piros')

                    if( result[0].havi_min < result[0].szum_kerek_havi ){
                        $('#eredmeny #szum_kerek_1ev #ar #havi_min').text( result[0].szum_kerek_havi + ' Ft + ÁFA / hó' )
                        $('#eredmeny #szum_kerek_1ev #ar #havi_min').addClass('eredmeny_piros')
                    }
                    else{
                        $('#eredmeny #szum_kerek_1ev #ar #havi_min').hide()
                    }
                }
            },
            error: function(xhr, resp, text) {
                alert(xhr, resp, text);
            }
        })
    });
});