import $ from 'jquery';
window.$ = window.jQuery = $;

import moment from 'moment';
require('bootstrap');

import './kalkulator';

import 'bootstrap-select';
import 'bootstrap-datetime-picker';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'summernote';

import 'jquery-ui/ui/widgets/sortable.js';

function handleCopyText(text) {
	const cb = navigator.clipboard;
	cb.writeText(text);
}

$('#container').on('click', '.confirm', function() {
	return confirm($(this).data('confirm') || 'Biztos benne?');
});

$(document).ajaxStart(function () {
	$('.ajax_loader').show();
});

$(document).ajaxStop(function () {
	$('.ajax_loader').hide();
});

function recalculate($form) {
	$.ajax({
		url: '/api/kalkulator/calculate', // url where to submit the request
		type : "POST", // type of action POST || GET
		dataType : 'json', // data type
		data : $form.serialize(), // post data || get data
		success : function(result) {
			console.log(result[0].szum_kerek_1ev);
			$form.find('#ar').val(result[0].szum_kerek_1ev);
		},
		error: function(xhr, resp, text) {
			alert(xhr, resp, text);
		}
	});
}

$(document).ready(function() {

	moment.locale('hu');

	$('.copy').click(function(event) {
		handleCopyText($(this).data('value'));
	});

	if ($('#container').find('[autofocus]').length == 0) {
		$('#container').find(':input:visible').first().focus();
	}

	$('[data-toggle="tooltip"]').tooltip({
		container: 'body'
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
//			'X-Requested-With': 'XMLHttpRequest'
		},
//		xhrFields: {
//			withCredentials: true
//		},
//		crossDomain: true
	});

	// törlés gomb
	$(document).on('click', '.torol', function(e) {
		e.preventDefault();
		if (confirm('Biztos benne?')) {
			var $this = $(this);
			$.ajax({
				url: $this.attr('href'),
				method: 'DELETE',
				success: function(resp) {
					if ($this.is('.refresh')) {
						return document.location.reload();
					}
					$('#item_' + resp.id).remove();
				},
				error: function() {
					alert('Hiba történt');
				}
			});
		}
	});

	function rendezheto() {
		$('.rendezheto').sortable({
			update: function (event, ui) {
				var $this = $(this);
				$.post($this.data('action'), {
					data: $this.sortable('serialize')
				});
			}
		});
	}
	rendezheto();

	$('.dropdown .active').parents('.dropdown').addClass('active');
	$('.dropdown').each(function() {
		if ($(this).find('li').length == 0) {
			$(this).remove();
		}
	});

	$('.not-required [required]').each(function() {
		$(this).removeAttr('required');
	});

	// kötelető mezőkhöz piros csillag
	$('[required]').each(function() {
		$(this).parents('.form-group').find('label').first().append('&nbsp;<span class="text-danger" title="Kötelező mező">*</span>');
	});

	// hibás mezők jelölése + tab fül
	$(':input').on('invalid', function() {
		window.scrollTo(0, 0);

		var $this = $(this),
			id = $this.parents('.tab-pane').attr('id');

		$('a[href="#' + id + '"]').addClass('bg-danger text-danger');
		$this
			.after('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>' +
				'<span class="sr-only">(kötelező mező)</span>')
			.parents('.form-group').addClass('has-error has-feedback');
	});

	// hibás mezők jelölése küldés után
	$('.alert.alert-danger[data-danger-fields]').each(function() {
		var mezo;
		$.each($(this).data('danger-fields'), function(i, field) {
			mezo = field.toString().split('.').join('');
			$('#' + mezo)
				.after('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>')
				.parents('.form-group').addClass('has-error has-feedback');
		});
	});

	$('.dtpicker').datetimepicker({
		language: 'hu',
		weekStart: 1,
		autoclose: 1,
		todayHighlight: 1,
		startView: 2,
		minView: 0,
		maxView: 4,
		format: 'yyyy-mm-dd hh:ii'
	});

	$('.datepicker').datetimepicker({
		language: 'hu',
		weekStart: 1,
		autoclose: 1,
		todayHighlight: 1,
		startView: 2,
		minView: 2,
		maxView: 4,
		format: 'yyyy-mm-dd'
	});

	$('.collapse').on('show.bs.collapse', function() {
		$('[href="#'+$(this).attr('id')+'"]').addClass('active').blur();
	});
	$('.collapse').on('hide.bs.collapse', function() {
		$('[href="#'+$(this).attr('id')+'"]').removeClass('active').blur();
	});

	// tab active
	var $navtabs = $('.nav-tabs');
	if ($navtabs.find('li.active:visible').length == 0) {
		var $tab = $navtabs.find('li a[href^="#"]:visible').first();
		$tab.tab('show');
	}

//	Dropzone.options.dropzone = {
//		queuecomplete: function() {
//			var $this = $(this.element);
//			$($this.data('ajaxtarget')).load($this.data('ajaxurl'), function() {
//				rendezheto();
//			});
//		},
//		dictDefaultMessage: '<button type="button" class="btn btn-success"><span class="glyphicon glyphicon-upload"></span> Kattintson vagy húzza ide a feltölteni kívánt fájlokat</button>'
//	};

	function menu_tipus() {
		var $this = $(this),
			value = $this.val();

		if (value == 'szoveg') {
			$('#szoveg_id').removeAttr('disabled').removeProp('disabled').parents('.form-group').slideDown();
		} else {
			$('#szoveg_id').attr('disabled', 'disabled').prop('disabled', 'disabled').parents('.form-group').slideUp();
		}
	}
	$('.menu_tipus').each(menu_tipus);
	$('.menu_tipus').on('change', menu_tipus);

	$('.bs-select').selectpicker({
		noneSelectedText: '(válasszon)',
		liveSearch: true,
		liveSearchPlaceholder: '(keresés)'
	});

//	$('.jcrop').each(function() {
//		var $this = $(this),
//			name = $this.data('name') + '_kivagasok',
//			aspectRatio = $this.data('aspect-ratio') || 1,
//			selection = $this.data('selection'),
//			$input = $('<input type="hidden" name="' + name + '[' + aspectRatio + ']">');
//
//		$this.parent().append($input);
//		setTimeout(function() { // hack!
//			$this.Jcrop({
//				trueSize: [
//					$this.get(0).naturalWidth,
//					$this.get(0).naturalHeight
//				],
//				keySupport: false,
//				aspectRatio: aspectRatio,
//				onChange: function(c) {
//					$input.val(JSON.stringify(c));
//				},
//				onSelect: function(c) {
//					$input.val(JSON.stringify(c));
//				},
//				setSelect: [
//					selection.x,
//					selection.y,
//					selection.w,
//					selection.h
//				],
//				boxWidth: $this.width(),
//				bgColor: ''
//			});
//		}, 400);
//	});

	$('.summernote').each(function() {
		var $this = $(this);
		$this.summernote({
			lang: 'hu-HU',
			height: 300,
			toolbar: [
				['style', ['style']],
				['font', ['bold', 'underline', 'clear']],
//				['fontname', ['fontname']],
				['color', ['color']],
				['para', ['ul', 'ol', 'paragraph']],
				['table', ['table']],
				['insert', ['link', 'picture', 'video']],
				['view', ['fullscreen', 'codeview', 'help']]
			],
			callbacks: {
				onImageUpload: function(files) {
					var data = new FormData();
					$.each(files, function(i, file) {
						data.append('files[]', file);
					});
					$.ajax({
						data: data,
						type: 'POST',
						url: $this.data('action'),
						cache: false,
						contentType: false,
						processData: false,
						success: function (resp) {
							$.each(resp, function(i, elem) {
								$this.summernote('insertImage', elem.url, elem.name);
							});
						}
					});
				}
			}
		});
	});

	function szolgaltatas_azonosito() {
		var $this = $(this),
			azon;
		$this.find('option').each(function() {
			azon = $(this).val();
			if (azon != '' && $this.val() != azon) {
				$('div#' + azon).slideUp().find(':input').attr('disabled', 'disabled').prop('disabled', true);
			}
		});
		if ($this.val()) {
			$('div#' + $this.val()).slideDown().find(':input').removeAttr('disabled').removeProp('disabled');
		}
		if ($('.uj-kalkulacio').length) {
			recalculate($('.uj-kalkulacio').parent());
		}
	}
	$('select.szolgaltatas_azonosito').on('change', szolgaltatas_azonosito);
	$('select.szolgaltatas_azonosito').each(szolgaltatas_azonosito);

	recalculate($('.uj-kalkulacio').parent());

	$('.uj-kalkulacio input').change(function() {
		recalculate($('.uj-kalkulacio').parent());
	});

	function cim_megegyezik() {
		var $this = $(this),
			$target = $($this.data('target'));
		if ($this.is(':checked')) {
			$target.slideUp().find(':input').attr('disabled', 'disabled').prop('disabled', true);
		} else {
			$target.slideDown().find(':input').removeAttr('disabled').removeProp('disabled');
		}
	}
	$('input.cim_megegyezik').on('change', cim_megegyezik);
	$('input.cim_megegyezik').each(cim_megegyezik);

	function ugyfel_szolgaltatas() {
		var $this = $(this),
			val = $this.val(),
			$target = $($this.data('target'));
		if (!val) {
			return $target.html('');
		}
		$.get($this.data('action'), {
			id: val
		}, function(resp) {
			if (resp.status == 'success') {
				$target.html(resp.html);
			} else {
				$target.html('');
			}
		});
	}
	$('select.ugyfel_szolgaltatas').on('change', ugyfel_szolgaltatas);
	$('select.ugyfel_szolgaltatas').each(ugyfel_szolgaltatas);

	$('.datum_duration').on('change', function() {
		var $this = $(this),
			$target = $($this.data('target')),
			duration = moment.duration($this.data('duration')),
			val = moment($this.val());
		if (!$target.val() && val) {
			$target.val(val.add(duration).format(val.creationData().format)).trigger('change');
		}
	});

	function ures_mezo() {
		var $this = $(this),
			$target = $this.parents('.form-group').find('.form-control');
		if ($this.is(':checked')) {
			$target.attr('disabled', 'disabled').prop('disabled', true);
		} else {
			$target.removeAttr('disabled').removeProp('disabled');
		}
	}
	$('.ures_mezo').on('change', ures_mezo);
	$('.ures_mezo').each(ures_mezo);
});

$('#ertesites_szoveg_modal').on('show.bs.modal', function (e) {
	var button = $(e.relatedTarget),
		$this = $(this);
	$this.find('.modal-body').html(button.data('content'));
});

// ----- ajax-os delegálások -----
$(document).on('submit', 'form.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$(this).ajaxSubmit({
		method: $this.data('method') || $this.attr('method') || 'POST',
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

$(document).on('change blur', 'form.auto :input', function (e) {
	e.preventDefault();
	$(this).parents('form').submit();
});

$(document).on('click', 'a.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$.ajax({
		url: $this.attr('href'),
		method: $this.data('method') || 'GET',
		data: $this.data(),
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

var cbs = {
	callbackHandler: function (cb, resp, $elem) {
		$.each(cb.split(',') || [], function (i, c) {
			if (c && $.isFunction(cbs[c])) {
				cbs[c](resp, $elem);
			}
		});
//	},
//	mozgatas_tetel: function (resp, $elem) {
//		$elem.find('.has-error').each(function () {
//			$(this).removeClass('has-error').find('span.help-block').remove();
//		});
//
//		if (resp.status == 'success') {
//			$($elem.data('target')).html(resp.html);
//			$elem.find(':input:visible').first().focus();
//			var termek_id = $elem.find('select#termek_id').find(':selected').next().val();
//			$elem.find('select,input').clearFields().selectpicker('refresh').trigger('change');
//			$elem.find('select#termek_id').val(termek_id).selectpicker('refresh').trigger('change')
//		} else if (resp.status == 'error') {
//			$.each(resp.errors, function (key, value) {
//				value = $.isArray(value) ? value : [value];
//				$elem.find('[name="' + key + '"]').parents('.form-group').addClass('has-error').append('<span class="help-block">' + value.join('<br>') + '</span>');
//			});
//		}
	}
};
